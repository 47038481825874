<script>
import { config } from "../../config";

export default {
  name: "email-link",
  data() {
    return { config };
  },
};
</script>

<template>
  <a :href="'mailto:' + config.contact.email">
    {{ config.contact.email }}
  </a>
</template>

<style scoped lang="scss"></style>
