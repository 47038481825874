<script>
export default {
  name: "contact",
  data() {
    return {
      showMap: false,
    };
  },
};
</script>

<template>
  <h2 class="display-2">Kontakt</h2>
  <div class="row flex-column flex-md-row">
    <div class="col">
      <h3>So erreichen Sie uns</h3>
      <h4>Mit öffentlichen Verkehrsmitteln:</h4>

      <p>U Bahnhof Hellersdorf mit der U5</p>
      <p>Tram: M6, 18</p>
      <p>Bus: X54, 195, N5</p>
      <p>
        Laufen Sie über den Alice- Salomonplatz direkt in die Peter- Weiß- Gasse
        Der Aufgang befindet sich auf der rechten Seite.
      </p>

      <h4>Mit dem Auto:</h4>
      <p>
        Über die Quedlinburger Straße oder die Stendaler Straße fahren sie
        direkt zum Marktplatzcenter Hellerdorf
      </p>
      <p>Parkmöglichkeiten gibt es im Center</p>
      <p>
        Der Eingang zu unserer Praxis befindet sich in der Gasse zwischen Alice
        Salomon Hochschule und Marktplatzcenter Hellersdorf.
      </p>
      <p>Ein Zugang vom Center zu uns gibt es nicht.</p>
      <p>Wir freuen uns auf Ihren Besuch.</p>
    </div>

    <div class="col overflow-hidden">
      <iframe
        v-if="showMap"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d764.3948269904404!2d13.604762403654597!3d52.53701762059039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84a154419c9a5%3A0x88b3ecbed7b0c105!2sSilke%20Thielemann!5e0!3m2!1sde!2sde!4v1730368280871!5m2!1sde!2sde"
        width="100%"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <img
        v-else
        @click="showMap = true"
        :title="$t('page_contact.map_preview_title')"
        class="map-preview"
        src="../assets/images/Anfahrt.png"
        alt="contact map"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.map-preview {
  cursor: zoom-in;
  transform: scale(1.5);
}
</style>
