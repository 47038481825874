<script>
import SvgIcon from "./svg/svg-icon.vue";

export default {
  name: "logo",
  components: { SvgIcon },
  props: {
    showName: {
      type: [Boolean],
      default: true,
    },
    showGraphic: {
      type: [Boolean],
      default: true,
    },
  },
};
</script>

<template>
  <a class="logo d-none d-md-inline-flex flex-row align-items-end" href="/">
    <svg-icon v-if="showGraphic" name="logoV2" :height="120"></svg-icon>
    <div v-if="showName" style="font-variant: all-small-caps">
      <div class="fw-bold display-6 text-justify line-1">P o d o l o g i e</div>
      <div class="text-justify">P r a x i s &emsp; B e c k e r</div>
    </div>
  </a>
</template>

<style scoped lang="scss">
a {
  text-decoration: none;
}

.line-1 {
  min-width: 100px;
}
</style>
