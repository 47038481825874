<script>
import placeholder from "../../assets/images/img-placeholder.png";

export default {
  name: "serviceThumbs",
  data() {
    return {
      services: [
        {
          src: placeholder,
          title: "services.foot_care_title",
        },
        {
          src: placeholder,
          title: "services.nail_care_title",
        },
        {
          src: placeholder,
          title: "services.prothetic_title",
        },
      ],
    };
  },
};
</script>

<template>
  <div class="services d-flex flex-column flex-md-row text-center gap-4">
    <div class="service" v-for="service in services">
      <img
        class="img-thumbnail img-fluid w-75 d-none d-md-inline-block"
        :src="service.src"
      />
      <img
        class="img-thumbnail img-fluid w-auto d-md-none"
        :src="service.src"
      />
      <div class="title">{{ $t(service.title) }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
img {
  max-height: 500px;
  max-width: 90%;
}
</style>
