export const config = {
  contact: {
    practiceName: "Podologie Praxis Becker",
    ownerName: "Bianca Becker",
    street: "Peter-Weiss-Gasse 1",
    zip: "12627",
    city: "Berlin",
    phone: "030/99283012",
    phoneLink: "+493099283012",
    fax: "030/99281725",
    email: "info@podologie-hellemitte.de",
  },
  openingHours: [
    {
      days: "Mo. - Do.",
      time: "8.00 - 18.00",
    },
    {
      days: "Fr.",
      time: "8.00 - 15.30",
    },
  ],
};
