<template>
  <div>
    <h2 class="display-2">{{ $t("page_imprint.headline") }}</h2>

    <p>
      {{ config.contact.practiceName }}<br />
      {{ config.contact.ownerName }}<br />
      {{ config.contact.street }}<br />
      {{ config.contact.zip }} {{ config.contact.city }}<br />
    </p>

    <p>
      {{ $t("global.phone") }}: <phone-link></phone-link><br />
      {{ $t("global.email") }}: <email-link></email-link>
    </p>

    <div v-html="$t('page_imprint.content')"></div>
  </div>
</template>

<script>
import { config } from "../config";
import PhoneLink from "../components/contacts/phone-link.vue";
import EmailLink from "../components/contacts/email-link.vue";

export default {
  name: "imprint",
  components: { EmailLink, PhoneLink },
  data() {
    return { config };
  },
};
</script>
