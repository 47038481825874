<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="700 400 600 900"
  >
    <defs>
      <linearGradient
        id="uuid-312b4a44-0cfe-41d1-899f-5e0d535cd617"
        x1="474.6"
        y1="1416"
        x2="740.1"
        y2="1211"
        gradientTransform="translate(236.01 -128.25) rotate(-6.57)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#283c93" />
        <stop offset="1" stop-color="#283c93" />
      </linearGradient>
      <linearGradient
        id="uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137"
        x1="746.09"
        y1="815.15"
        x2="596.17"
        y2="1213.4"
        gradientTransform="translate(236.01 -128.25) rotate(-6.57)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#ffd824" />
        <stop offset="1" stop-color="#eb3939" />
      </linearGradient>
      <linearGradient
        id="uuid-b57a8ff2-4f9c-49c9-8c49-29d683566dba"
        x1="479.76"
        y1="633.03"
        x2="532.18"
        y2="795.82"
        gradientTransform="translate(505.17 -292.66) rotate(13.94)"
        xlink:href="#uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137"
      />
      <linearGradient
        id="uuid-4a87af89-f57e-48f3-b9fc-359afa1ac6b1"
        x1="646.99"
        y1="648.55"
        x2="644"
        y2="765.07"
        gradientTransform="translate(312.56 -206.8)"
        xlink:href="#uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137"
      />
      <linearGradient
        id="uuid-cd306a29-6679-4163-918c-a6f4f8c3bc83"
        x1="769.29"
        y1="670.01"
        x2="729.74"
        y2="770.27"
        gradientTransform="translate(1536.98 -419.43) rotate(72.98)"
        xlink:href="#uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137"
      />
      <linearGradient
        id="uuid-bcbb6658-a827-47ff-b857-3539a72acdba"
        x1="855.64"
        y1="749.02"
        x2="799.25"
        y2="812.77"
        gradientTransform="translate(1231.88 -580.34) rotate(50.88)"
        xlink:href="#uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137"
      />
      <linearGradient
        id="uuid-7eb7c494-0486-4e91-837c-746238a703cf"
        x1="912.89"
        y1="835.21"
        x2="842.99"
        y2="883.96"
        gradientTransform="translate(1017.88 -585.31) rotate(36.71)"
        xlink:href="#uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137"
      />
      <linearGradient
        id="uuid-72c99d86-4704-4720-954c-03b30989b815"
        x1="889.16"
        y1="923.6"
        x2="676.79"
        y2="1076.46"
        xlink:href="#uuid-312b4a44-0cfe-41d1-899f-5e0d535cd617"
      />
    </defs>

    <g>
      <path
        class="foot-section-bottom"
        d="m1111.32,1160.38c-50.09,72.5-103.42,97.06-147.87,91.15-22.59-2.98-42.85-13.8-59.27-30.17-20.43-20.41-24.63-42.07-19.65-64.89,9.06-41.3,48.11-86.37,75.46-134.38,2.64-4.71,5.2-9.45,7.6-14.18,2.4-4.74,4.59-9.45,6.51-14.12,8.47-20.48,11.94-40.13,6.53-58.31,80.58,43.1,125.66,143.98,130.68,224.89Z"
      />
      <path
        class="foot-light"
        d="m1111.32,1160.38c-75.55,109.36-158.44,109.54-207.14,60.98-20.43-20.41-24.63-42.07-19.64-64.88,36.76,55.39,195.16,35.42,226.78,3.91Z"
      />
      <path
        class="foot-light"
        d="m963.45,1251.53c-22.58-2.98-42.85-13.8-59.27-30.17-20.43-20.41-24.63-42.07-19.64-64.88,9.06-41.31,48.11-86.37,75.46-134.38-27.82,55.49-29.19,178.21,3.45,229.44Z"
      />
      <path
        class="foot-section-middle"
        d="m1193.3,1003.94s0,.04,0,.07c-1.36,4.6-2.78,9.2-4.27,13.77-14.55,44.45-35.89,87.22-64.27,125.3-4.5,6.06-8.99,11.87-13.43,17.3-5.02-80.91-50.22-181.55-130.68-224.89-42.04-22.65-78.16-52.46-105.54-85.07-13.05-15.53-24.15-31.76-32.9-48.12v-.05c-12.95-24.02-20.91-48.46-22.99-71.74-1.2-13.26-.45-26.12,2.4-38.37,1.41-6.17,3.37-12.17,5.82-17.98,11.42-26.59,34.28-49.1,70.83-64.19l.05-.05c19.48-8.08,42.88-14.03,70.49-17.39h.08c3.54-.44,7.14-.83,10.81-1.18,15.21-1.42,29.71-1.34,43.5.15,17.63,1.87,34.06,5.98,49.28,12.09-.31-.07-.62-.13-.93-.2-60.67-12.47-105.12,19.69-113.57,53.26-40.6,161.04,271.21,140.46,235.36,347.29Z"
      />
      <path
        class="foot-section-middle-shadow"
        d="m1111.32,1160.38c-5.02-80.91-50.22-181.55-130.69-224.9-42.03-22.65-78.16-52.46-105.54-85.08-32.25-38.4-52.42-80.74-55.89-119.9-1.2-13.25-.46-26.12,2.39-38.37h.05c-4.04,184.58,429.04,126.29,289.68,468.25Z"
      />
      <ellipse
        class="toe"
        cx="824.86"
        cy="533.26"
        rx="53.96"
        ry="71.74"
        transform="translate(-134.57 322.88) rotate(-20.51)"
      />
      <ellipse
        class="toe-2"
        cx="958.06"
        cy="498.77"
        rx="36.68"
        ry="43.16"
        transform="translate(-50.81 112.98) rotate(-6.58)"
      />
      <ellipse
        class="toe-3"
        cx="1061.38"
        cy="507.54"
        rx="39.86"
        ry="33.88"
        transform="translate(369.82 1459.3) rotate(-79.55)"
      />
      <ellipse
        class="toe-4"
        cx="1146.38"
        cy="554.21"
        rx="35.42"
        ry="30.1"
        transform="translate(62.46 1222.38) rotate(-57.45)"
      />
      <ellipse
        class="toe-5"
        cx="1202.87"
        cy="628.41"
        rx="30.8"
        ry="26.18"
        transform="translate(-103.6 995.73) rotate(-43.29)"
      />
      <path
        class="foot-section-middle-outer-line"
        d="m1111.32,1160.38c-5.02-80.91-50.22-181.55-130.69-224.9,20.86,8.95,39.71,22.63,55.83,38.9,16.21,16.21,29.69,35.05,40.79,55.02,10.93,20.13,19.54,41.47,25.4,63.56,5.85,21.99,9.14,44.8,8.67,67.43Z"
      />
      <path
        class="foot-section-right"
        d="m1210.14,823.1c1.82,16.68,2.65,33.78,2.51,51.11-.37,42.72-6.75,86.85-19.36,129.73,35.86-206.83-275.95-186.25-235.36-347.29,8.5-33.75,53.36-66.06,114.51-53.06l1.08.43c15.54,6.34,29.81,14.74,42.77,24.91,1.04.82,2.08,1.65,3.1,2.48,51.4,42.03,82.1,112.26,90.74,191.7Z"
      />
      <path
        class="foot-light"
        d="m1210.14,823.1c1.82,16.68,2.65,33.78,2.51,51.11-20.97-101.99-193.42-101.81-167.72-203.74,5.72-22.72,34.56-44.53,74.46-39.07,51.4,42.03,82.1,112.26,90.74,191.7Z"
      />
      <path
        class="foot-middle-line-shadow"
        d="m1111.32,1160.38c-14.61-52.04-74.89-143.66-137.21-166.58,8.47-20.48,11.94-40.13,6.53-58.31,80.58,43.1,125.66,143.98,130.68,224.89Z"
      />
      <path
        class="foot-middle-line-shadow"
        d="m1193.3,1003.94c8.45-116-113.27-172.2-157.1-193.99-43.82-21.8-93.66-60.03-101.51-117.63-7.78-57.09,64.9-110.78,136.83-88.93-60.67-12.47-105.12,19.69-113.57,53.26-40.6,161.04,271.21,140.46,235.36,347.29Z"
      />
    </g>
  </svg>
</template>

<style>
.foot-light,
.toe-3,
.toe-5,
.toe-2,
.toe-4,
.foot-middle-line-shadow,
.foot-section-middle,
.foot-section-bottom,
.foot-middle-line,
.foot-section-right,
.foot-section-middle-outer-line,
.foot-section-middle-shadow,
.toe {
  stroke-width: 0;
}

.foot-light {
  fill: #fff;
}

.foot-light,
.foot-middle-line,
.foot-section-middle-outer-line {
  mix-blend-mode: screen;
}

.foot-light,
.foot-section-middle-shadow {
  opacity: 0.1;
}

.toe-3 {
  fill: url(#uuid-cd306a29-6679-4163-918c-a6f4f8c3bc83);
}

.toe-5 {
  fill: url(#uuid-7eb7c494-0486-4e91-837c-746238a703cf);
}

.toe-2 {
  fill: url(#uuid-4a87af89-f57e-48f3-b9fc-359afa1ac6b1);
}

.toe-4 {
  fill: url(#uuid-bcbb6658-a827-47ff-b857-3539a72acdba);
}

.foot-middle-line-shadow {
  opacity: 0.2;
}

.foot-section-middle {
  fill: url(#uuid-2911c32d-f5b0-4a1d-95dc-9f60d7ff7137);
}

.foot-section-bottom {
  fill: url(#uuid-312b4a44-0cfe-41d1-899f-5e0d535cd617);
}

.foot-section-right {
  fill: url(#uuid-72c99d86-4704-4720-954c-03b30989b815);
}

.toe {
  fill: url(#uuid-b57a8ff2-4f9c-49c9-8c49-29d683566dba);
}
</style>

<script setup></script>
