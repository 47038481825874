<script>
import Waves from "../svg/waves.vue";

export default {
  name: "image-slider",
  components: { Waves },
};
</script>

<template>
  <div class="image-slider full-span position-relative">
    <waves class="position-absolute bottom-0"></waves>
  </div>
</template>

<style scoped lang="scss">
.image-slider {
  background: var(--sw-color-primary-lighten);
  border: 1px solid var(--sw-color-primary);
  border-width: 1px 0;
  min-height: 100px;
}
</style>
