<template>
  <layout>
    <component :is="page"></component>
  </layout>
</template>

<script>
import Layout from "./components/layout";
import Home from "./pages/home";
import Contact from "./pages/contact";
import Practice from "./pages/practice";
import Services from "./pages/services";
import Privacy from "./pages/privacy";
import Imprint from "./pages/imprint";
import Playground from "./pages/playground.vue";

const pages = {
  home: Home,
  kontakt: Contact,
  praxis: Practice,
  leistungen: Services,
  impressum: Imprint,
  datenschutz: Privacy,
  playground: Playground,
};

export default {
  components: {
    Layout,
    Home,
    Contact,
    Imprint,
    Privacy,
    Playground,
  },
  computed: {
    page() {
      return pages[location.pathname.substring(1) || "home"];
    },
  },
};
</script>
