<script>
import { config } from "../../config";

export default {
  name: "phone-link",
  data() {
    return { config };
  },
};
</script>

<template>
  <a :href="'tel:' + config.contact.phoneLink">{{ config.contact.phone }}</a>
</template>

<style scoped lang="scss"></style>
