<template>
<div class="what-is">
  <h2>Was ist Podologie?</h2>

  <p>
    Vom griechischen „ podos“ für Fuß und „logie“ für die Lehre abgeleitet, ist der Podologe, im
    Podologengesetz (PodG) vom 04. Dezember 2001 geregelt, befähigt durch Anwendung geeigneter
    Verfahren nach den anerkannten Regeln der Hygiene allgemeine und spezifische, fußpflegerische
    Maßnahmen selbstständig auszuführen, pathologische Veränderungen oder Symptome von
    Erkrankungen am Fuß, die ärztliche Abklärung erfordern, zu erkennen,unter ärztlicher Anleitung
    oder auf ärztliche Veranlassung medizinisch indizierte podologische Behandlungen durchzuführen
    und damit bei der Prävention, Therapie und Rehabilitation von Fußerkrankungen mitzuwirken.
  </p>

  <p>
    Der Name „Podologe“ ist gesetzlich geschützt.
  </p>
</div>
</template>

<script>
export default {
  name: 'whatIs',
};
</script>

<style scoped>

</style>
