<script>
import Separator from "../components/separator";
import Panel from "../components/panel";
import WhatIs from "../components/sections/whatIs";

export default {
  name: "playground",
  components: { WhatIs, Panel, Separator },
};
</script>

<template>
  <what-is></what-is>
  <panel><separator></separator></panel>
</template>

<style scoped lang="scss"></style>
