<template>
  <ul>
    <li>{{ $t("services.list_1") }}</li>
    <li>{{ $t("services.list_2") }}</li>
    <li>{{ $t("services.list_3") }}</li>
    <li>{{ $t("services.list_4") }}</li>
    <li>{{ $t("services.list_5") }}</li>
    <li>{{ $t("services.list_6") }}</li>
    <li>{{ $t("services.list_7") }}</li>
    <li>{{ $t("services.list_8") }}</li>
    <li>{{ $t("services.list_9") }}</li>
    <li>{{ $t("services.list_10") }}</li>
    <li>{{ $t("services.list_11") }}</li>
  </ul>
</template>

<script>
export default {
  name: "serviceList",
};
</script>

<style scoped></style>
