<template>
  <div
    class="page-footer d-flex justify-content-between align-items-end p-3 full-span"
  >
    <logo :show-name="false"></logo>
    <contact></contact>

    <div class="d-flex flex-column align-items-end">
      <a v-for="navItem in navItems" :href="navItem.link">{{
        $t(navItem.name)
      }}</a>
    </div>
  </div>
</template>

<script>
import Panel from "./panel";
import Logo from "./logo";
import Contact from "./contact";
import { ExtraNavigationItems } from "./navigation/navigation.types";
export default {
  name: "page-footer",
  components: { Contact, Logo, Panel },
  data() {
    return {
      navItems: ExtraNavigationItems,
    };
  },
};
</script>

<style scoped lang="scss">
.page-footer {
  background-color: var(--sw-color-primary);
  color: var(--sw-color-white);
}
</style>
