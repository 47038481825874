<script>
import Panel from "../panel.vue";
import { NavigationItems } from "./navigation.types";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SlideInNavigation from "./slide-in-navigation";
export default {
  name: "navigation",
  components: { SlideInNavigation, FontAwesomeIcon, Panel },
  data() {
    return {
      navItems: NavigationItems,
      currentPath: window.location.pathname,
    };
  },
  computed: {
    currentNavItem() {
      return this.navItems.find(
        (item) => item.link === (this.currentPath || "/"),
      );
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.pathname;
    });
  },
};
</script>

<template>
  <ul class="nav nav-underline d-none d-md-flex">
    <li v-for="item in navItems" class="nav-item">
      <a
        class="nav-link"
        :class="{ active: currentNavItem && currentNavItem.link === item.link }"
        :href="item.link"
        >{{ $t(item.name) }}</a
      >
    </li>
  </ul>

  <div class="d-md-none">
    <slide-in-navigation></slide-in-navigation>
  </div>
</template>

<style lang="scss" scoped></style>
