<script>
import placeholder from "/src/assets/images/img-placeholder.png";

export default {
  name: "practice",
  data() {
    return {
      placeholder,
    };
  },
};
</script>

<template>
  <h2 class="display-2">{{ $t("page_practice.headline") }}</h2>
  <div class="row">
    <div class="col">Lorem Ipsum Dolor Sit Amet</div>
    <div class="col"><img :src="placeholder" style="max-height: 30vh" /></div>
  </div>

  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col end-0">
          <img class="img-thumbnail col-6" :src="placeholder" />
          <img class="img-thumbnail col-6" :src="placeholder" />
          <img class="img-thumbnail col-6" :src="placeholder" />
          <img class="img-thumbnail col-6" :src="placeholder" />
        </div>
      </div>
    </div>
    <div class="col">Lorem Ipsum Dolor Sit Amet</div>
  </div>
</template>

<style scoped lang="scss"></style>
