export const NavigationItems = [
  { name: "nav.home", link: "/" },
  { name: "nav.practice", link: "/praxis" },
  { name: "nav.services", link: "/leistungen" },
  { name: "nav.contact", link: "/kontakt" },
];

export const ExtraNavigationItems = [
  { name: "nav.imprint", link: "/impressum" },
  { name: "nav.privacy", link: "/datenschutz" },
];
