<script>
export default {
  name: "waves",
};
</script>

<template>
  <svg viewBox="0 0 500 50">
    <path
      d="M 0 30 C 150 200 150 0 600 50 L 500 50 L 0 50"
      fill="rgb(57, 27, 112)"
    ></path>
    <path
      d="M 0 30 C 150 100 180 00 700 150 L 500 50 L 0 50"
      fill="#283c93"
      opacity="0.8"
    ></path>
    <path
      d="M 0 30 C 215 100 100 0 500 50 L 500 50 L 0 50"
      fill="#283c93"
      opacity="0.5"
    ></path>
  </svg>
</template>

<style scoped lang="scss"></style>
