<template>
  <div class="page container-fluid">
    <header>
      <page-header></page-header>
    </header>

    <main class="d-flex flex-column">
      <slot></slot>
    </main>

    <footer>
      <page-footer></page-footer>
    </footer>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";

import PageHeader from "./page-header";
import PageFooter from "./page-footer";

export default {
  components: {
    PageFooter,
    PageHeader,
  },
};
</script>

<style lang="scss">
$colorPrimary: #283c93;

:root {
  --sw-color-primary: #{$colorPrimary};
  --sw-color-primary-lighten: #{adjust-color($colorPrimary, $lightness: 45%)};
  --sw-color-secondary: #f48f0d;
  --sw-color-white: #ffffff;
  --sw-color-gray-1: #eeeeee;
  --sw-color-gray-2: #cccccc;
  --sw-color-gray-3: #aaaaaa;
  --sw-color-black: #000000;
  --sw-color-bg: #ffffff33;

  --bs-emphasis-color: var(--sw-color-primary);
}

* {
  transition: all 0.3s;
}

body {
  background-color: var(--sw-color-gray-1);
  color: var(--sw-color-primary);
  font-family: Calibri, Arial, sans-serif;
  height: 100vh;
  margin: 0;
  min-width: 360px;
}
a {
  color: var(--sw-color-secondary);
}
div,
span,
p {
  hyphens: auto;
  word-break: break-word;
}
img {
  height: auto;
  max-width: 100%;
}
table {
  width: 100%;
}
h2 {
  border-bottom: 1px solid var(--sw-color-primary);
}
h3 {
  color: var(--sw-color-secondary);
}
hr {
  border: 2px solid var(--sw-color-secondary);
}
header {
  grid-area: head;
}
main {
  grid-area: main;
}
footer {
  grid-column: foot;
}
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 99%;
  @media screen and (min-width: 775px) {
    max-width: 750px;
  }
}
.page {
  display: grid;
  height: 100vh;
  grid-template:
    [header-left]
    "head" [header-right] [main-left] "main" 1fr [main-right] [footer-left] "foot"
    [footer-right] / 1fr;
  width: 100%;
}
.text-info {
  background-color: var(--sw-color-bg);
  border: 1px solid var(--sw-color-primary);
  padding: 4px 8px;
}
.full-span {
  margin-left: -12px;
  margin-right: -12px;
}
.text-justify {
  letter-spacing: 0.1px;
  text-align: justify;
  text-align-last: justify;
}
</style>
