<template>
  <div class="panel">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "panel",
};
</script>

<style scoped lang="scss">
.panel {
  background-color: var(--sw-color-white);
  position: relative;

  &:before,
  &:after {
    background-color: inherit;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  &:after {
    left: 0;
  }
}
</style>
