<script>
import ServiceList from "../components/services/services-list.vue";

export default {
  name: "services",
  components: { ServiceList },
};
</script>

<template>
  <div class="services">
    <h2 class="display-2">{{ $t("page_services.headline") }}</h2>

    <service-list></service-list>
  </div>
</template>

<style scoped lang="scss"></style>
