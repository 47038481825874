<template>
  <div>
    <h2 class="display-2">{{ $t("page_privacy.headline") }}</h2>

    <div
      v-html="
        $t('page_privacy.content', {
          domain: $t('global.domain'),
          contact: `<p>
              ${config.contact.practiceName}<br />
              ${config.contact.ownerName}<br />
              ${config.contact.street}<br />
              ${config.contact.zip} ${config.contact.city}
            </p>
            <p>
              ${$t('global.phone')}: ${config.contact.phone}<br />
              ${$t('global.email')}: ${config.contact.email}
            </p>
          `,
        })
      "
    ></div>
  </div>
</template>

<script>
import { config } from "../config";
import EmailLink from "../components/contacts/email-link.vue";
import PhoneLink from "../components/contacts/phone-link.vue";

export default {
  name: "privacy",
  components: { PhoneLink, EmailLink },
  data() {
    return {
      config,
    };
  },
};
</script>

<style scoped lang="scss">
th {
  background-color: var(--sw-color-primary);
  border-bottom: 1px solid var(--sw-color-primary);
  color: var(--sw-color-white);
}

th,
td {
  padding: 2px;
}
</style>
