<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ExtraNavigationItems, NavigationItems } from "./navigation.types";

export default {
  name: "slide-in-navigation",
  components: { FontAwesomeIcon },
  data() {
    return {
      navItems: [...NavigationItems, ...ExtraNavigationItems],
      currentPath: window.location.pathname,
    };
  },
  computed: {
    currentNavItem() {
      return this.navItems.find(
        (item) => item.link === (this.currentPath || "/"),
      );
    },
  },
  mounted() {
    window.addEventListener("hashchange", () => {
      this.currentPath = window.location.pathname;
    });
  },
};
</script>

<template>
  <button
    class="btn btn-outline-secondary"
    type="button"
    data-bs-toggle="offcanvas"
    data-bs-target="#slideNavigation"
    aria-controls="slideNavigation"
  >
    <font-awesome-icon icon="fa-bars"></font-awesome-icon>
  </button>

  <div
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="slideNavigation"
    aria-labelledby="slideNavigationLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="slideNavigationLabel"></h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body">
      <ul class="nav nav-underline flex-column">
        <li v-for="item in navItems" class="nav-item">
          <a
            class="nav-link"
            :class="{
              active: currentNavItem && currentNavItem.link === item.link,
            }"
            :href="item.link"
            >{{ $t(item.name) }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
