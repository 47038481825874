import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./index.vue";
import de from "./translations/de";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "bootstrap";

const app = createApp(App);

const i18n = createI18n({
  locale: "de",
  messages: { de },
});

library.add(faBars);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(i18n);
app.mount("#app");
