<template>
  <div>
    <image-slider style="margin-top: -1px"></image-slider>
    <service-thumbs class="my-4"></service-thumbs>
  </div>
</template>

<script>
import ImageSlider from "../components/image-slider/image-slider";
import ServiceThumbs from "../components/services/service-thumbs.vue";

export default {
  name: "home",
  components: {
    ServiceThumbs,
    ImageSlider,
  },
};
</script>
