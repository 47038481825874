<script>
import { config } from "../config";
import PhoneLink from "./contacts/phone-link.vue";
import EmailLink from "./contacts/email-link.vue";

export default {
  name: "head-contact",
  components: { EmailLink, PhoneLink },
  data() {
    return { config };
  },
};
</script>

<template>
  <div class="d-flex flex-column align-items-end">
    <div class="text-nowrap">
      {{ $t("global.phone") }}: <phone-link></phone-link>
    </div>
    <div class="text-nowrap">
      {{ $t("global.fax") }}: {{ config.contact.fax }}
    </div>
    <div class="text-nowrap">
      {{ $t("global.email") }}: <email-link></email-link>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
