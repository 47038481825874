<template>
  <div class="page-header px-3 py-2 full-span">
    <div class="d-flex gap-2 justify-content-between">
      <logo></logo>
      <navigation></navigation>
      <head-contact></head-contact>
    </div>
  </div>
</template>

<script>
import Navigation from "./navigation/navigation";
import Logo from "./logo";
import HeadContact from "./head-contact";

export default {
  name: "pageHeader",
  components: { HeadContact, Logo, Navigation },
};
</script>

<style scoped lang="scss">
.page-header {
  background-color: var(--sw-color-white);
  border-bottom: 1px solid var(--sw-color-primary);
}
</style>
