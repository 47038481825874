<template>
  <component
    :width="width"
    :height="height"
    :fill="color"
    :is="name"
  ></component>
</template>

<script>
import Foot from "./icons/foot.vue";
import Logo from "./icons/logo.vue";
import LogoV2 from "./icons/logo_v2.vue";

export default {
  name: "svgIcon",
  props: {
    name: {
      type: String,
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    color: {
      type: String,
      default: "#000000",
    },
  },
  components: {
    Foot,
    Logo,
    LogoV2,
  },
  computed: {
    path() {
      const icons = {
        foot: Foot,
        logo: Logo,
        logoV2: LogoV2,
      };

      return icons[this.name];
    },
  },
};
</script>
