<script>
import { config } from "../config";
import PhoneLink from "./contacts/phone-link.vue";
import EmailLink from "./contacts/email-link.vue";

export default {
  name: "contact",
  components: { EmailLink, PhoneLink },
  data() {
    return { config };
  },
};
</script>

<template>
  <div class="contact">
    <div>{{ config.contact.street }}</div>
    <div>{{ config.contact.zip }} {{ config.contact.city }}</div>
    <div>{{ $t("global.phone_short") }}: <phone-link></phone-link></div>
    <div>{{ $t("global.fax") }}: {{ config.contact.fax }}</div>
    <div>{{ $t("global.email") }}: <email-link></email-link></div>
  </div>
</template>

<style scoped lang="scss">
.contact {
  min-width: 260px;
}
</style>
